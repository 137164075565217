import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'

Swiper.use([Navigation, Autoplay, Pagination])

const newSwiper = new Swiper('.js-reviewsSlider', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  pagination: {
    el: '.reviews-slider__pagination',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: ".reviews-arrow__next",
    prevEl: ".reviews-arrow__prev",
  },
})