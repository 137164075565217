
if (document.querySelector('#map')) {
  ymaps.ready(init);
  var myMapTwo,
    myPlacemark;
  /*Здесь надо указывать координаты центра карты*/
  function init() {
    myMapTwo = new ymaps.Map("map", {
      center: [45.06442175680212,38.961605071411064],
      zoom: 14,
      controls: ['geolocationControl']
    });
  
    myMapTwo.behaviors.disable([
      'scrollZoom',
    ]);
    /*Здесь указывать кодинаты для местоположения метки*/
    myPlacemark = new ymaps.Placemark(myMapTwo.getCenter(), {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: 'img/balun.svg',
      // Размеры метки.
      iconImageSize: [80, 97],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-40, -48.5]
    }),
  
    myMapTwo.geoObjects.add(myPlacemark);
  };
}