// if (document.querySelector('.js-parentFolders')) {
//   const parentHeight = document.querySelector('.js-parentFolders').scrollHeight + 40;
  
//   console.log(parentHeight)

//   document.querySelectorAll('.image-folders__item').forEach(elem => {
//     elem.setAttribute('data-height-hover', parentHeight)

//     elem.addEventListener('mouseover', (event) => {
//       const eventTar = event.target;
//       eventTar.style.height = `${parentHeight + 40}px`;
//       eventTar.style.marginTop = `-${(parentHeight/2) +40}px`;
//       // console.log('fire')
//     })

//     elem.addEventListener('mouseout', (event) => {
//       const eventTar = event.target;
//       // console.log('cold')
//       eventTar.style.height = `${(parentHeight/2) + 40}px`;
//       eventTar.style.marginTop = null;
//     })
//   })
// }