import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'

Swiper.use([Navigation, Autoplay, Pagination])

const newSwiper = new Swiper('.js-docsSlider', {
  slidesPerView: 4,
  spaceBetween: 32,
  pagination: {
    el: '.docs-slider__pagination',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: ".docs-arrow__next",
    prevEl: ".docs-arrow__prev",
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
    },
    // when window width is >= 1000px
    1000: {
      slidesPerView: 3,
    },
    // when window width is >= 1300px
    1300: {
      slidesPerView: 4,
    }
  }
})