if (document.querySelector('.js-parrentAccordion')) {
  document.querySelector('.js-parrentAccordion').addEventListener('click', event => {
    const eventTar = event.target;
  
    if (eventTar.classList.contains('service-list__link')) {
      const idTab = eventTar.dataset.tab;
      const tabElem = document.querySelector(`#${idTab}`);
      const parrent = eventTar.closest('.js-parrentAccordion');
  
      console.log(eventTar)
  
      if (eventTar.closest('.js-accordionLink').classList.contains('is-active')) {
        eventTar.closest('.js-accordionLink').classList.remove('is-active');
        tabElem.classList.remove('is-active');
        tabElem.style.maxHeight = null;
      } else {
        parrent.querySelectorAll('.js-accordionLink').forEach(elem => {
          elem.classList.remove('is-active')
        });
    
        parrent.querySelectorAll('.js-tab').forEach(elem => {
          elem.classList.remove('is-active');
          elem.style.maxHeight = null;
        });
  
        eventTar.closest('.js-accordionLink').classList.add('is-active');
        tabElem.classList.add('is-active');
        tabElem.style.maxHeight = `${tabElem.scrollHeight}px`;
      }
    }
  })
}